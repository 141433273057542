<template>
  <v-card>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card-text class="pb-0">
        <v-alert color="purple" border="left" elevation="1" colored-border>
          <div v-for="app in allowedAppsForTenant" :key="app.key">
            {{ app.name }} {{ $t('userAddEditModal.availableSeats') }}:
            {{
              app.availableSeats === null
                ? $t('userAddEditModal.unlimited')
                : app.availableSeats
            }}
          </div>
        </v-alert>

        <v-text-field
          v-model="name"
          :label="$t('userAddEditModal.name')"
          :rules="nameRules"
        />

        <v-text-field
          v-model="email"
          :label="$t('userAddEditModal.email')"
          :rules="emailRules"
        />

        <v-autocomplete
          v-model="departmentId"
          :items="departmentOptions"
          :label="$t('userAddEditModal.department')"
          item-text="name"
          item-value="id"
          :menu-props="{ closeOnContentClick: true, offsetY: true, auto: true }"
        />

        <v-autocomplete
          v-if="isSchedulerIncludedInAllowedApps"
          v-model="additionalDepartmentIds"
          :items="additionalDepartmentOptions"
          :label="$t('userAddEditModal.additionalDepartments')"
          item-text="name"
          item-value="id"
          multiple
          :menu-props="{ offsetY: true }"
        />

        <div style="height: 10px" />
        <v-divider class="mb-4" />

        <v-card-title class="grey--text text--darken-2 pa-0">
          {{ $t('userAddEditModal.SSOConfigurationTitle') }}
        </v-card-title>

        <v-checkbox
          v-if="showSsoOptions"
          v-model="isSSOEnabled"
          :label="$t('userAddEditModal.enableSSO')"
          hide-details
        />

        <v-text-field
          v-if="isSSOEnabled"
          v-model="samlNameId"
          :placeholder="email"
          :label="$t('userAddEditModal.samlNameId')"
          :hint="$t('userAddEditModal.enterIdpEmailAddress')"
          persistent-hint
        />

        <div style="height: 20px" />
        <v-divider class="mb-4" />

        <template v-if="canChooseApps">
          <span class="text-subtitle-1">
            {{ $t('userAddEditModal.allowedApps') }}
          </span>
          <v-checkbox
            v-for="allowedAppForTenant in allowedAppsForTenant"
            :key="allowedAppForTenant.key + 'checkbox'"
            v-model="allowedAppsForUser"
            :label="allowedAppForTenant.name"
            :value="allowedAppForTenant.key"
            dense
            :hide-details="allowedAppForTenant.isSeatAvailable"
            :error-messages="
              isAppNameDisabled(allowedAppForTenant)
                ? [$t('userAddEditModal.noMoreSeatsRemaining')]
                : []
            "
            :disabled="isAppNameDisabled(allowedAppForTenant)"
          />
          <v-alert
            v-if="allowedAppsForUser.length === 0"
            outlined
            type="warning"
            class="ma-4"
          >
            {{ $t('userAddEditModal.pleaseChooseAtleastOneApp') }}
          </v-alert>
          <v-divider class="mt-4" />
        </template>

        <v-checkbox
          v-if="!isDeactivated"
          v-model="isAdmin"
          :label="$t('userAddEditModal.admin')"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          :disabled="!valid || allowedAppsForUser.length === 0"
          :loading="loading"
          color="accent"
          @click="submit"
        >
          {{ $t('common.submit') }}
        </v-btn>
      </v-card-actions>
    </v-form>

    <ConfirmRemoveUserFromApp
      ref="confirmRemoveUserFromApp"
      :user-id="userId"
      :user-name="name"
      :user-email="email"
      :user-department-name="user?.department?.name"
    />
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { addUser, editUser } from '@/api/admin.api';
import openSnackbar from '../../../helpers';
import ConfirmRemoveUserFromApp from './ConfirmRemoveUserFromApp.vue';

export default {
  components: {
    ConfirmRemoveUserFromApp,
  },

  props: {
    dialog: {
      type: Boolean,
      required: true,
    },

    isEditing: {
      type: Boolean,
      required: true,
    },

    user: {
      type: Object,
      default: null,
    },

    isDeactivated: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      userId: -1,
      name: '',
      email: '',
      departmentId: null,
      isAdmin: false,
      isSSOEnabled: false,
      valid: false,
      allowedAppsForUser: [],
      originalAllowedAppsForUser: [],
      additionalDepartmentIds: [],
      samlNameId: null,
      loading: false,
    };
  },

  computed: {
    ...mapGetters([
      'allowedAppsForTenant',
      'currentTenant',
      'isTenantManager',
      'currentTenantFromSubdomain',
    ]),

    departmentOptions() {
      const { departments } = this.$store.getters;
      const departmentOptions = [];
      for (let i = 0; i < departments.length; i += 1) {
        departmentOptions.push({
          id: departments[i].id,
          name: departments[i].name,
        });
      }
      return departmentOptions;
    },

    additionalDepartmentOptions() {
      const { departments } = this.$store.getters;
      const departmentOptions = [];
      for (let i = 0; i < departments.length; i += 1) {
        departmentOptions.push({
          id: departments[i].id,
          name: departments[i].name,
        });
      }
      return departmentOptions;
    },

    nameRules() {
      return [(v) => !!v || this.$t('validation.required')];
    },

    emailRules() {
      const emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return [
        (v) => !!v || this.$t('validation.emailRequired'),
        (v) => emailRegex.test(v) || this.$t('validation.emailValidMust'),
      ];
    },

    isSchedulerIncludedInAllowedApps() {
      if (
        this.allowedAppsForTenant.some(
          (allowedApp) => allowedApp.key === 'scheduler',
        )
      ) {
        return this.allowedAppsForUser.some(
          (allowedApp) => allowedApp === 'scheduler',
        );
      }
      return false;
    },

    canChooseApps() {
      if (this.isDeactivated) {
        return false;
      }
      if (this.allowedAppsForTenant.length === 1) {
        const theOnlyAllowedAppForTenant = this.allowedAppsForTenant[0]['key'];
        if (
          this.originalAllowedAppsForUser.includes(theOnlyAllowedAppForTenant)
        ) {
          return false;
        }
      }
      return true;
    },
    showSsoOptions() {
      if (!this.currentTenant && this.isTenantManager) {
        return (
          this.currentTenantFromSubdomain.is_idp_configured &&
          this.currentTenantFromSubdomain.feature_sso_users_limit !== 0
        );
      }
      return (
        this.currentTenant.is_sso_enabled &&
        this.currentTenant.feature_sso_users_limit !== 0
      );
    },
  },

  watch: {
    dialog(newValue) {
      if (newValue === true) {
        this.runOpenRoutine();
      }
    },

    isSSOEnabled(newValue) {
      if (newValue === false) {
        if (this.isEditing) {
          this.samlNameId = this.user.saml_name_id;
        } else {
          this.samlNameId = null;
        }
      }
    },
  },

  async mounted() {
    this.runOpenRoutine();
  },

  methods: {
    ...mapActions(['updateAllowedAppsForTenant']),

    isAppNameDisabled(allowedAppForTenant) {
      return (
        allowedAppForTenant.isSeatAvailable === false &&
        !this.originalAllowedAppsForUser.includes(allowedAppForTenant.key)
      );
    },

    runOpenRoutine() {
      this.resetForm();
      if (this.isEditing === true) {
        this.$nextTick(() => {
          // this.resetForm() is kind of async
          this.populateForm(this.user);
        });
      } else {
        this.setAllowedAppsForUser();
      }
    },

    populateForm(user) {
      this.userId = user.id;
      this.name = user.name;
      this.email = user.email;
      this.samlNameId = user.saml_name_id;
      this.departmentId = user.department.id;
      this.isAdmin = user.is_admin === 1;
      this.isSSOEnabled = user.is_sso_enabled;
      this.allowedAppsForUser = user.allowedApps;
      this.originalAllowedAppsForUser = [...user.allowedApps];
      this.additionalDepartmentIds = user.additionalDepartments.map(
        (department) => department.id,
      );
    },

    async submitAsAddForm() {
      const additionalDepartmentIds = this.additionalDepartmentIds.filter(
        (id) => id !== this.departmentId,
      );
      await addUser(
        this.name,
        this.email,
        this.departmentId,
        this.isAdmin,
        this.allowedAppsForUser,
        additionalDepartmentIds,
        this.samlNameId,
        this.isSSOEnabled,
      )
        .then((response) => {
          if (response.data === 'success') {
            this.$emit('user-added');
            this.$emit('close');
            openSnackbar(this.$t('userAddEditModal.added'), 'success');
          }
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response.data);
          if (error.response.data === 'quota_finished') {
            openSnackbar(this.$t('userAddEditModal.quotaFinished'), 'error');
          } else if (error.response.data === 'user_already_exists') {
            openSnackbar(
              this.$t('userAddEditModal.userAlreadyExists'),
              'error',
            );
          } else {
            openSnackbar(this.$t('common.somethingWentWrong'), 'error');
            throw error;
          }
        })
        .finally(() => {
          this.updateAllowedAppsForTenant();
        });
    },

    async submitAsEditForm() {
      const additionalDepartmentIds = this.additionalDepartmentIds.filter(
        (id) => id !== this.departmentId,
      );
      await editUser(
        this.userId,
        this.name,
        this.email,
        this.departmentId,
        this.isAdmin,
        this.allowedAppsForUser,
        additionalDepartmentIds,
        this.samlNameId,
        this.isSSOEnabled,
      )
        .then((response) => {
          this.$emit('user-edited');
          if (response.data === 'sent-register-mail-to-new-email-id') {
            openSnackbar(
              this.$t('userAddEditModal.editedAndInvited', {
                name: this.name,
              }),
              'success',
            );
          } else {
            openSnackbar(this.$t('userAddEditModal.edited'), 'success');
          }
        })
        .catch((error) => {
          if (
            error.response.data.message ===
            'cannot-make-last-admin-as-non-admin'
          ) {
            openSnackbar(
              this.$t('userAddEditModal.cannotRemoveLastAdmin'),
              'error',
              10000,
            );
            return;
          }
          if (error.response.data === 'duplicate-email') {
            openSnackbar(
              this.$t('userAddEditModal.duplicateEmail'),
              'error',
              10000,
            );
            return;
          }
          if (error.response.data === 'belongs-to-multiple-tenants') {
            openSnackbar(
              this.$t('userAddEditModal.belongsToMultipleTenants'),
              'error',
              10000,
            );
            return;
          }
          if (error.response.data === 'sso_users_limit_exceeded') {
            openSnackbar(
              this.$t('userAddEditModal.ssoUsersLimitExceeded'),
              'error',
              10000,
            );
            return;
          }
          throw error;
        })
        .finally(() => {
          this.updateAllowedAppsForTenant();
        });
    },

    async submit() {
      if (this.$refs.form.validate() === false) {
        return;
      }
      const isBeingRemovedFromMeishi = this.isBeingRemovedFromMeishi(
        this.originalAllowedAppsForUser,
        this.allowedAppsForUser,
      );
      const isBeingRemovedFromScheduler = this.isBeingRemovedFromScheduler(
        this.originalAllowedAppsForUser,
        this.allowedAppsForUser,
      );
      if (isBeingRemovedFromMeishi || isBeingRemovedFromScheduler) {
        const confirm = await this.$refs.confirmRemoveUserFromApp.open(
          isBeingRemovedFromMeishi,
          isBeingRemovedFromScheduler,
        );
        if (confirm !== true) {
          return;
        }
      }

      this.loading = true;
      if (this.isEditing === true) {
        await this.submitAsEditForm();
      } else {
        await this.submitAsAddForm();
      }
      this.loading = false;
    },

    isBeingRemovedFromMeishi(originalAllowedAppsForUser, allowedAppsForUser) {
      const wasOriginallyAllowed =
        originalAllowedAppsForUser.includes('meishi');
      const isCurrentlyAllowed = allowedAppsForUser.includes('meishi');

      return wasOriginallyAllowed && !isCurrentlyAllowed;
    },

    isBeingRemovedFromScheduler(
      originalAllowedAppsForUser,
      allowedAppsForUser,
    ) {
      const wasOriginallyAllowed =
        originalAllowedAppsForUser.includes('scheduler');
      const isCurrentlyAllowed = allowedAppsForUser.includes('scheduler');

      return wasOriginallyAllowed && !isCurrentlyAllowed;
    },

    resetForm() {
      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }
      this.userId = -1;
      this.name = '';
      this.email = '';
      this.departmentId = null;
      this.isAdmin = false;
      this.isSSOEnabled = false;
      this.valid = false;
      this.allowedAppsForUser = [];
      this.additionalDepartmentIds = [];
      this.samlNameId = null;
    },

    setAllowedAppsForUser() {
      this.allowedAppsForTenant.forEach((allowedApp) => {
        if (allowedApp.isSeatAvailable) {
          this.allowedAppsForUser.push(allowedApp.key);
        }
      });
    },
  },
};
</script>
