<template>
  <v-icon>{{ icon }}</v-icon>
</template>

<script>
export default {
  props: {
    agent: {
      type: Object,
      required: true,
    }
  },

  computed:{
    icon() {
      switch (this.agent.device) {
        case 'Macintosh':
          return 'mdi-monitor';
        case 'iPhone':
          return 'mdi-cellphone';
        case 'iPad':
          return 'mdi-tablet';
        default:
          if (this.agent.os === 'AndroidOS') {
            return 'mdi-cellphone';
          }
          return 'mdi-monitor-cellphone';
      }
    },
  },

}
</script>

