<template>
  <v-dialog v-model="isOpen" width="400">
    <v-card>
      <v-card-title class="title">
        {{ $t('MyAccountManagementPage.changeLanguage') }}
        <v-spacer />
        <v-btn icon color="grey" @click="isOpen = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        {{ $t('MyAccountManagementPage.changeLanguageBody') }}
        <v-radio-group v-model="chosenLanguage">
          <v-radio label="English" value="en" class="pb-2" />
          <v-radio label="日本語" value="ja" />
        </v-radio-group>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import openSnackbar from '../helpers';

export default {
  props: {
    isChangeLanguageOpen: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['user']),
    isOpen: {
      get() {
        return this.isChangeLanguageOpen;
      },
      set() {
        this.$emit('close');
      },
    },
    chosenLanguage: {
      get() {
        return this.user.language;
      },
      set(val) {
        this.changeLanguage(val);
      },
    },
  },
  methods: {
    changeLanguage(newValue) {
      axios
        .patch('/user/language', {
          language: newValue,
        })
        .then(() => {
          this.$i18n.locale = newValue;
          this.$store.dispatch('getUserInfo');
          openSnackbar(
            this.$t('MyAccountManagementPage.languageUpdated'),
            'success',
          );
        })
        .catch((error) => {
          openSnackbar(this.$t('common.somethingWentWrong'), 'error');
          throw error;
        });
    },
  },
};
</script>

<style>
</style>