<template>
  <v-app>
    <custom-nav-drawer v-if="isPageAfterLogin" id="navigationDrawer" />
    <toolbar v-if="isPageAfterLogin" id="toolBar" />
    <router-view id="main" />
    <snackbar />
    <change-password />
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Snackbar from './components/Snackbar.vue';
import ChangePassword from './components/ChangePasswordModal.vue';
import Toolbar from './components/Toolbar/Toolbar.vue';
import CustomNavDrawer from './components/CustomNavDrawer.vue';
import { setTenantTagInSentry } from './plugins/sentry';

export default {
  components: {
    Snackbar,
    ChangePassword,
    Toolbar,
    CustomNavDrawer,
  },
  computed: {
    ...mapGetters(['currentTenant', 'isTenantManager']),
    isPageAfterLogin() {
      if (this.$route.meta.requiresAuth) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    this.fetchSubdomain();
  },
  methods: {
    ...mapActions(['fetchTenantForTenantManagerUsingSubdomain']),
    async fetchSubdomain() {
      const subdomain = App.helpers.getSubdomain();
      setTenantTagInSentry(subdomain);
      if (subdomain && this.isTenantManager) {
        await this.fetchTenantForTenantManagerUsingSubdomain(subdomain);
      }
    },
  },
};
</script>
