import httpClient from './httpClient';

const END_POINT = '/outlook-sso';

const mapOutlookAccessTokenToUser = (token) =>
  httpClient.post(`${END_POINT}/map-user`, {
    token,
  });

export { mapOutlookAccessTokenToUser as default };
