const state = {
  open: false,
  message: null,
  color: null,
  timeout: null,
};

const getters = {
  snackbar: ({open, color, timeout, message}) => ({
    open,
    color,
    timeout,
    message,
  }),
};

const mutations = {
  OPEN_SNACKBAR: (_state, payload) => {
    _state.open = true;
    _state.message = payload.message;
    _state.color = payload.color;
    _state.timeout = payload.timeout;
  },
  CLOSE_SNACKBAR: (_state) => {
    _state.open = false;
    _state.message = null;
    _state.color = null;
    _state.timeout = null;
  },
};


export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
