<template>
  <v-dialog v-model="isOpen" max-width="600px">
    <v-card>
      <v-card-title class="headline">
        {{ $t('GetMoreServicesPageConfirmationDialog.title') }}
        <v-spacer />
        <v-btn icon color="grey" @click="isOpen = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <div class="subtitle-1">
          {{ $t('GetMoreServicesPageConfirmationDialog.messageLine1') }}
          <br />
          {{ $t('GetMoreServicesPageConfirmationDialog.messageLine2') }}
        </div>

        <v-simple-table class="mt-4">
          <template #default>
            <tbody>
              <tr v-for="item in fields" :key="item.key">
                <td>{{ $t('GetMoreServicesPage.' + item.key) }}</td>
                <td>
                  {{ item.value === true ? $t('common.yes') : item.value }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn color="accent" text @click="isOpen = false">
          {{ $t('common.cancel') }}
        </v-btn>
        <v-btn
          color="accent"
          @click="
            $emit('submit');
            isOpen = false;
          "
        >
          {{ $t('common.yes') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    fields: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    isOpen: false,
  }),

  methods: {
    open() {
      this.isOpen = true;
    },
  },
};
</script>
