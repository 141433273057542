<template>
  <v-main>
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="8" lg="6">
          <v-card width="1000">
            <v-alert outlined type="success" prominent text>
              <v-row align="center">
                <v-col class="grow">
                  {{ $t('SamlTestPassPage.message') }}
                </v-col>
                <v-col class="shrink" @click="close">
                  <v-btn>{{ $t('common.close') }}</v-btn>
                </v-col>
              </v-row>
            </v-alert>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
export default {
  methods: {
    close() {
      window.close();
    },
  },
}
</script>