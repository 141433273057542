import Vue from 'vue';
import VueRouter from 'vue-router';
import i18n from '../i18n';
import store from '../store/index';

import Login from '../pages/Login.vue';
import ForgotPassword from '../pages/ForgotPassword.vue';
import ResetPassword from '../pages/ResetPassword.vue';
import UserManagement from '../pages/Admin/UserManagement/UserManagementPage.vue';
import DeactivatedUserManagement from '../pages/Admin/DeactivatedUserManagement/DeactivatedUserManagementPage.vue';
import DepartmentManagement from '../pages/Admin/DepartmentManagement/DepartmentManagementPage.vue';
import SsoSettingPage from '../pages/Admin/SSO/SsoSettingPage.vue';
import EnterOtp from '../pages/Otp/EnterOtp.vue';
import Twofa from '../pages/Twofa/Twofa.vue';
import MyAccountManagementPage from '../pages/MyAccountManagementPage.vue';
import BackupLogin from '../pages/Otp/BackupLogin.vue';
import VerifyPassword from '../pages/VerifyPassword.vue';
import RegisterUser from '../pages/RegisterUser.vue';
import VerifyEmail from '../pages/VerifyEmail.vue';
import SamlLoginErrorPage from '../pages/SamlLoginErrorPage.vue';
import SamlTestPassPage from '../pages/SamlTestPassPage.vue';
import TenantSettings from '../pages/Admin/TenantSettings/index.vue';
import ContactDetailsPage from '../pages/Admin/ContactDetailsPage.vue';
import SubscriptionsPage from '../pages/Admin/SubscriptionsPage.vue';
import GetMoreServicesPage from '../pages/Admin/GetMoreServicesPage.vue';
import IpAddressRestrictionPage from '../pages/Admin/IpAddressRestrictionPage.vue';
import OpenMeishiMobile from '../pages/OpenMeishiMobile.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    alias: '/',
    meta: {
      page: 'login',
      requiresAuth: false,
    },
  },
  {
    path: '/forgot',
    name: 'forgot',
    component: ForgotPassword,
    meta: {
      page: 'forgot',
      requiresAuth: false,
    },
  },
  {
    path: '/reset',
    name: 'reset',
    component: ResetPassword,
    meta: {
      page: 'reset',
      requiresAuth: false,
    },
  },
  {
    path: '/otp',
    name: 'enter-otp',
    component: EnterOtp,
    meta: {
      page: 'enter-otp',
      requiresAuth: false,
    },
  },
  {
    path: '/recovery',
    name: 'backup-login',
    component: BackupLogin,
    meta: {
      page: 'backup-login',
      requiresAuth: false,
    },
  },
  {
    path: '/register/:email',
    name: 'register',
    component: RegisterUser,
    meta: {
      page: 'register',
      requiresAuth: false,
    },
  },
  {
    path: '/verify/:email',
    name: 'verify',
    component: VerifyEmail,
    meta: {
      page: 'verify',
      requiresAuth: false,
    },
  },
  {
    path: '/admin/users',
    name: 'admin-user-management',
    component: UserManagement,
    meta: {
      page: 'admin-user-management',
      requiresAuth: true,
      requiresAdminOrTenantManager: true,
    },
  },
  {
    path: '/admin/deactivated',
    name: 'admin-deactivated-user-management',
    component: DeactivatedUserManagement,
    meta: {
      page: 'admin-deactivated-user-management',
      requiresAuth: true,
      requiresAdminOrTenantManager: true,
    },
  },

  {
    path: '/admin/departments',
    name: 'admin-department-management',
    component: DepartmentManagement,
    meta: {
      page: 'admin-department-management',
      requiresAuth: true,
      requiresAdminOrTenantManager: true,
    },
  },

  {
    path: '/admin/tenant-settings',
    name: 'tenant-settings',
    component: TenantSettings,
    meta: {
      page: 'tenant-settings',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },

  {
    path: '/admin/sso-setting',
    name: 'sso-setting',
    component: SsoSettingPage,
    meta: {
      page: 'sso-setting',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },

  {
    path: '/admin/ip-restriction',
    name: 'ip-restriction',
    component: IpAddressRestrictionPage,
    meta: {
      page: 'ip-restriction',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },

  {
    path: '/admin/contact-details',
    name: 'contact-details',
    component: ContactDetailsPage,
    meta: {
      page: 'contact-details',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },

  {
    path: '/admin/subscriptions',
    name: 'subscriptions',
    component: SubscriptionsPage,
    meta: {
      page: 'subscriptions',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },

  {
    path: '/admin/get-more-services',
    name: 'get-more-services',
    component: GetMoreServicesPage,
    meta: {
      page: 'get-more-services',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },

  {
    path: '/twofa',
    name: 'twofa',
    component: Twofa,
    meta: {
      page: 'twofa',
      requiresAuth: true,
      restrictedForSso: true,
    },
  },
  {
    path: '/review-login-activity',
    name: 'review-login-activity',
    component: () =>
      import(
        /* webpackChunkName: "review-login-activity" */ '../pages/ReviewLoginActivityPage/ReviewLoginActivityPage.vue'
      ),
    meta: {
      page: 'review-login-activity',
      requiresAuth: true,
    },
  },
  {
    path: '/my-account',
    name: 'my-account',
    component: MyAccountManagementPage,
    alias: '/security',
    meta: {
      page: 'my-account',
      requiresAuth: true,
    },
  },
  {
    path: '/verify-password',
    name: 'verify-password',
    component: VerifyPassword,
    meta: {
      page: 'verify-password',
      requiresAuth: true,
      restrictedForSso: true,
    },
  },
  {
    path: '/saml-login-error',
    name: 'saml-login-error',
    component: SamlLoginErrorPage,
    meta: {
      page: 'saml-login-error',
      requiresAuth: true,
    },
  },
  {
    path: '/saml-test-passed',
    name: 'saml-test-passed',
    component: SamlTestPassPage,
    meta: {
      page: 'saml-test-passed',
      requiresAuth: true,
    },
  },
  {
    path: '/open-meishi-mobile',
    name: 'open-meishi-mobile',
    component: OpenMeishiMobile,
    meta: {
      page: 'open-meishi-mobile',
      requiresAuth: false,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

function redirectToLoginPage() {
  const url = new URL(window.location.href);
  const redirectTo = encodeURIComponent(`${url.pathname}${url.search}`);
  window.location.href = `${process.env.BASE_URL}login?continue=${redirectTo}`;
}

function handleForAuthRequiredRoutes(to, from, next) {
  const { isSsoEnabled, isAdmin, isTenantManager } = store.getters;
  if (!store.getters.isAuthenticated) {
    redirectToLoginPage();
  } else if (to.meta.restrictedForSso && isSsoEnabled) {
    from();
  } else if (to.meta.requiresAdmin && isAdmin !== true) {
    next({ path: '/my-account' });
  } else if (
    to.meta.requiresAdminOrTenantManager &&
    isAdmin !== true &&
    isTenantManager !== true
  ) {
    next({ path: '/my-account' });
  } else {
    next();
  }
}

function checkAuthAndRedirect(to, next) {
  if (
    !store.getters.isAuthenticated ||
    to.name === 'login' ||
    to.name === 'verify' ||
    to.name === 'reset' ||
    to.name === 'open-meishi-mobile'
  ) {
    next();
  } else {
    next({ path: '/my-account' });
  }
}

router.beforeEach(async (to, from, next) => {
  if (!to.meta.requiresAuth) {
    checkAuthAndRedirect(to, next);
  } else {
    handleForAuthRequiredRoutes(to, from, next);
  }
});

router.afterEach((to) => {
  Vue.nextTick(() => {
    const { page } = to.meta;
    let documentTitle = i18n.t(`documentTitles.${page}`);
    documentTitle = `${documentTitle} - NEXTa`;
    document.title = documentTitle;
  });
});

export default router;
