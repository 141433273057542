<template>
  <v-list-item lines="three">
    <v-list-item-action>
      <DeviceModalListItemDeviceIcon :agent="trustedDevice.agent" />
    </v-list-item-action>

    <v-list-item-content>
      <v-list-item-title>
        {{ getDevice(trustedDevice.agent) }}・{{ getApp(trustedDevice.agent) }}
      </v-list-item-title>
      <v-list-item-subtitle>
        <v-tooltip bottom>
          <template #activator="{ on }">
            <span v-on="on">
              {{ getLocation(trustedDevice.location) }}・{{
                getTimeFromNow(trustedDevice.created_at)
              }}
            </span>
          </template>
          <span>{{
            $t('TrustedDevicesModalListItem.createdAt', {
              timestamp: formatTimestamp(trustedDevice.created_at),
            })
          }}</span>
        </v-tooltip>
      </v-list-item-subtitle>
    </v-list-item-content>

    <v-list-item-action>
      <v-menu>
        <template #activator="{ on }">
          <v-btn icon class="mx-0" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item @click="confirmDelete">
            <v-list-item-title>
              {{ $t('TrustedDevicesModalListItem.deleteTrustedDevice') }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-list-item-action>
    <Confirm ref="confirm" />
  </v-list-item>
</template>

<script>
import dateTimeHelpers from '@/mixins/dateTimeHelpers';
import openSnackbar from '@/helpers';
import Confirm from '@/components/Confirm.vue';
import DeviceModalListItemDeviceIcon from '@/components/Security/DeviceModalListItemDeviceIcon.vue';
import devicesModalListItemMixin from '@/components/Security/devicesModalListItemMixin';
import { deleteTrustedDevice } from '@/api/trustedDevices.api';

export default {
  components: { DeviceModalListItemDeviceIcon, Confirm },

  props: {
    trustedDevice: {
      type: Object,
      required: true,
    },
  },

  mixins: [dateTimeHelpers, devicesModalListItemMixin],

  methods: {
    async confirmDelete() {
      const confirm = await this.$refs.confirm.open(
        this.$t('TrustedDevicesModalListItem.confirmDelete', {
          name: `${this.getDevice(this.trustedDevice.agent)}・${this.getApp(
            this.trustedDevice.agent,
          )}`,
        }),
        this.$t('TrustedDevicesModalListItem.confirmDeleteBody'),
        {
          color: 'warning',
          width: 500,
        },
      );
      if (confirm === true) {
        await this.deleteTrustedDevice();
      }
    },

    async deleteTrustedDevice() {
      try {
        await deleteTrustedDevice(this.trustedDevice);
        this.$emit('refresh');
        const message = this.$t(
          'TrustedDevicesModalListItem.deletedSuccessfully',
        );
        openSnackbar(message, 'success');
      } catch (error) {
        const message = this.$t('common.somethingWentWrong');
        openSnackbar(message, 'error');
        throw error;
      }
    },
  },
};
</script>

