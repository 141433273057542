import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":"640"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t('TrustedDevicesModal.devicesThatYouTrust'))+" "),_c(VSpacer),_c(VBtn,{attrs:{"icon":"","color":"grey"},on:{"click":_vm.close}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,[_c(VContainer,[_c(VRow,[_c(VCol,[_vm._v(" "+_vm._s(_vm.$t('TrustedDevicesModal.ifALoginIsDetectedFromTrustedDeviceWeWillNotSendLoginAlert'))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$t('TrustedDevicesModal.alsoIfYouHaveEnabled2FAWeWillNotAskForLoginCode'))+" ")])],1),_c(VRow,[_c(VCol,[(_vm.isLoading)?_c(VLayout,{attrs:{"align-center":"","justify-center":"","column":""}},[_c(VFlex,{attrs:{"row":"","align-center":""}},[_c(VProgressCircular,{attrs:{"indeterminate":"","color":"primary"}})],1)],1):_c(VCard,{staticClass:"v-card-scrollable",attrs:{"height":"420px"}},[_c(VCardText,{staticClass:"v-card__text-scrollable"},[(_vm.trustedDevices.length === 0)?_c('p',{staticClass:"align-center justify-center"},[_vm._v(" "+_vm._s(_vm.$t('TrustedDevicesModal.currentlyYouHaveNotMarkedAnyDeviceAsTrusted'))+" "+_vm._s(_vm.$t('TrustedDevicesModal.youCanMarkADeviceTrustedFromSuspiciousLoginMail'))+" "+_vm._s(_vm.$t('TrustedDevicesModal.alsoYouCanMarkADeviceTrustedWhenDoing2FA'))+" ")]):_c(VList,_vm._l((_vm.trustedDevices),function(sessionDevice){return _c('div',{key:sessionDevice.id},[_c('TrustedDeviceModalListItem',{attrs:{"trusted-device":sessionDevice,"show-logout-button":""},on:{"refresh":_vm.fetchTrustedDevices}}),_c(VDivider)],1)}),0)],1)],1)],1)],1),_c(VRow,{staticClass:"text-body-2"},[_c(VCol,[_vm._v(" "+_vm._s(_vm.$t('Devices.locationsBasedOnIp'))+" ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }