<template>
  <v-dialog v-model="dialog" max-width="500px">
    <template #activator="{ on }">
      <v-btn small rounded outlined color="accent" v-on="on">
        <v-icon class="pr-2">
          mdi-help-circle
        </v-icon>
        {{ buttonText }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="headline">
        {{ $t('SecureAccountDialog.letsSecure') }}
      </v-card-title>
      <v-divider />
      <v-card-text class="mt-4 text--primary">
        {{ $t('SecureAccountDialog.recommendChangePassword') }}
      </v-card-text>
      <v-card-text class="text--primary">
        {{ $t('SecureAccountDialog.effectOfChangePassword') }}
      </v-card-text>
      <v-card-actions>
        <div class="flex-grow-1" />
        <v-btn color="accent" outlined @click="dialog = false">
          {{ $t('common.cancel') }}
        </v-btn>
        <v-btn color="accent" @click="openChangePasswordModal">
          {{ $t('SecureAccountDialog.changePassword') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    buttonText: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    dialog: false,
  }),
  methods: {
    openChangePasswordModal() {
      this.dialog = false;
      this.$store.dispatch('openChangePasswordModal');
    },
  },
};
</script>
